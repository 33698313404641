import React from "react";
import './Game.css';
import {useOutletContext} from "react-router-dom";
import {Room} from "../../../api/main";


function Game() {
    const room = useOutletContext<Room>();

    return (
        <div className="Game">
            <h1>Игровые действия</h1>
            <button>Распределить роли</button>
        </div>
    );
}

export default Game;
