import React from "react";
import './MyRole.css';
import {useNavigate, useOutletContext} from "react-router-dom";
import {Room} from "../../../api/main";


function MyRole() {
    const navigate = useNavigate();
    const room = useOutletContext<Room>();

    if (room.isCreator) {
        navigate(`/room/${room.id}/members`)
        return null;
    }

    return (
        <div className="Role">
            <h1>Ваша роль</h1>
            <p>
                Когда игра начнется, ваша роль появится здесь
            </p>
        </div>
    );
}

export default MyRole;
