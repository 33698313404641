import React from "react";
import {useNavigate} from "react-router-dom";

import './Home.css';


function Home() {
    const navigate = useNavigate();

    const createRoom = () => {
        navigate("/room/1");
    }

    return (
        <div className="Home">
            <h1>Two room and a boom</h1>
            <button className="Home__create_room" onClick={createRoom}>Создать комнату</button>
            <p className="Home__join_text">
                Если вы хотите присоединиться к существующей комнате, перейдите по ссылке на вкладке «Участники».
            </p>
        </div>
    );
}

export default Home;
