import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import './App.css';
import Home from "./pages/Home/Home";
import RoomPage from "./pages/Room/RoomPage";
import MyRole from "./pages/Room/MyRole/MyRole";
import Members from "./pages/Room/Members/Members";
import Roles from "./pages/Room/Roles/Roles";
import Game from "./pages/Room/Game/Game";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "room/:roomId",
        element: <RoomPage/>,
        children: [
            {
                index: true,
                element: <MyRole/>,
            },
            {
                path: "members",
                element: <Members/>,
            },
            {
                path: "roles",
                element: <Roles/>,
            },
            {
                path: "game",
                element: <Game/>,
            }
        ]
    },
]);

function App() {
    return (
        <div className="App">
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;
