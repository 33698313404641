import React, {useEffect, useState} from "react";
import {Link, Outlet, useParams} from "react-router-dom";

import './RoomPage.css';
import {Room} from "../../api/main";
import Loader from "../../components/Loader/Loader";

function NavBar({roomId}: { roomId: string }) {
    return (
        <div className="RoomPage__navbar">
            <Link to={`/room/${roomId}/members`}>Members</Link>
            <Link to={`/room/${roomId}/roles`}>Roles</Link>
            <Link to={`/room/${roomId}/game`}>Game</Link>
        </div>
    );
}

function RoomPage() {
    const [room, setRoom] = useState<Room | undefined>();
    const {roomId} = useParams();

    useEffect(() => {
        setInterval(() => {
            const id = (Math.random() * 1000).toFixed(0);
            setRoom({
                id: id,
                joinURL: `http://localhost:3000/room/${id}`,
                isCreator: true,
            })
        }, 1000)
    }, [roomId]);

    if (!room) {
        return <Loader/>;
    }

    return (
        <div className="RoomPage">
            <Outlet context={room}/>
            <div className="RoomPage__navbar_background"></div>
            {room.isCreator && <NavBar roomId={roomId as string}/>}
        </div>
    );
}

export default RoomPage;
