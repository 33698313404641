import React, {useState} from "react";
import QRCode from 'qrcode';
import './Members.css';
import {useOutletContext} from "react-router-dom";
import {Room} from "../../../api/main";


function Members() {
    const room = useOutletContext<Room>();

    const [qrCodeURL, setQRCodeURL] = useState("");

    QRCode.toDataURL(room.joinURL).then(setQRCodeURL);

    return (
        <div className="Members">
            <h1>Участники</h1>
            <p>В комнате пока нет участников</p>
            <h1>Добавить участника</h1>
            <p>Для добавления новых участников используйте ссылку:</p>
            <div className="Members__copy_field">{room.joinURL}</div>
            <p>или QR-код:</p>
            <img src={qrCodeURL} alt={room.joinURL}/>
        </div>
    );
}

export default Members;
