import React, {useState} from "react";
import {useOutletContext} from "react-router-dom";
import Select from 'react-select';
import leven from 'leven';

import './Roles.css';
import {Room} from "../../../api/main";
import {getRole, Role, roles, Team} from "../../../common/roles";
import {ReactComponent as AddIcon} from './add.svg';
import {ReactComponent as DeleteIcon} from './delete.svg';


const defaultRoles = new Map([
    ["blue_president", getRole("blue_president")],
    ["blue_team", getRole("blue_team", 1)],
    ["blue_spy", getRole("blue_spy")],
    ["red_bomber", getRole("red_bomber")],
    ["red_team", getRole("red_team", 1)],
    ["red_spy", getRole("red_spy")],
]);
const mandatoryRoleKeys = [
    "blue_president",
    "red_bomber",
];

const teamOptions = [
    {value: null, label: "Любая команда"},
    {value: Team.Blue, label: "Синяя команда"},
    {value: Team.Red, label: "Красная команда"},
    {value: Team.Grey, label: "Серая команда"},
];
const teams = {
    [Team.Grey]: {
        label: "Серая",
        activeColor: "#4a4f4f",
        disabledColor: "#909092",
    },
    [Team.Blue]: {
        label: "Синяя",
        activeColor: "#3343ad",
        disabledColor: "#649EDE",
    },
    [Team.Red]: {
        label: "Красная",
        activeColor: "#c4182b",
        disabledColor: "#ef4e6c",
    },
}

function countMismatchingLetters(a: string, b: string): number {
    let result = 0;
    for (let i = 0; i < Math.min(a.length, b.length); i++) {
        if (a[i] !== b[i]) {
            result++
        }
    }
    return result;
}

interface RoleItemProps {
    role: Role
    active?: boolean
    onAdd?: (role: Role) => void
    onDelete?: (role: Role) => void
}

function RoleItem(props: RoleItemProps) {
    const {role, active = true, onAdd, onDelete} = props;

    return <div
        className="RoleItem"
        style={{backgroundColor: active ? teams[props.role.team].activeColor : teams[role.team].disabledColor}}
    >
        <div>{role.name}</div>
        <div>{role.count !== undefined && `x${role.count}`}</div>
        {active && onAdd && <AddIcon className="RoleItem__action" onClick={() => onAdd(role)}/>}
        {active && onDelete && <DeleteIcon className="RoleItem__action" onClick={() => onDelete(role)}/>}
    </div>
}

function Roles() {
    const room = useOutletContext<Room>();

    const [selectedRoles, setSelectedRoles] = useState(defaultRoles);
    const addRole = (role: Role) => {
        const newSelectedRoles = new Map(selectedRoles);
        let newSelectedRole = newSelectedRoles.get(role.key);
        if (newSelectedRole && newSelectedRole.count) {
            newSelectedRole.count++;
        } else {
            newSelectedRoles.set(role.key, {...role, count: role.count && 1});
        }
        setSelectedRoles(newSelectedRoles);
    };
    const removeRole = (role: Role) => {
        const newSelectedRoles = new Map(selectedRoles);
        let newSelectedRole = newSelectedRoles.get(role.key);
        if (newSelectedRole && newSelectedRole.count && newSelectedRole.count > 1) {
            newSelectedRole.count--;
        } else {
            newSelectedRoles.delete(role.key);
        }
        setSelectedRoles(newSelectedRoles);
    }

    const selectedRolesArray = roles
        .map(role => selectedRoles.get(role.key))
        .filter(role => role !== undefined) as Role[]

    const [team, setTeam] = useState<{value: string | null, label: string} | null>(teamOptions[0]);
    let unselectedRoles = roles
        .map(role => {
            const selectedRole = selectedRoles.get(role.key)
            return {...role, count: selectedRole?.count ? role.count as number - selectedRole?.count : role.count}
        })
        .filter(role => (team?.value === null || role.team === team?.value))
        .filter(role => (!role.count && !selectedRoles.has(role.key)) || (role.count && role.count > 0));

    const [roleSearch, setRoleSearch] = useState('');
    if (roleSearch !== '') {
        const estimatedRoles = unselectedRoles
            .map(role => ({
                ...role,
                leven: leven(roleSearch, role.name),
                mismatchingCount: countMismatchingLetters(roleSearch, role.name)
            }))
            .filter(role => role.leven < role.name.length);
        estimatedRoles.sort((a, b) => (a.leven + a.mismatchingCount) - (b.leven + b.mismatchingCount));
        unselectedRoles = estimatedRoles;
    }

    return (
        <div className="Roles">
            <h1>Выбранные роли</h1>
            {selectedRolesArray.length
                ? selectedRolesArray.map(role => (
                    <RoleItem
                        key={role.key}
                        role={role}
                        onDelete={mandatoryRoleKeys.includes(role.key) ? undefined : removeRole}
                    />
                ))
                : <p>Ролей пока нет</p>}

            <h1>Все роли</h1>
            <Select
                className="Roles__team"
                placeholder="Команда"
                value={team}
                onChange={setTeam}
                options={teamOptions}
            />
            <input
                className="Roles__role BaseInput"
                type="text"
                value={roleSearch}
                onChange={e => setRoleSearch(e.target.value)}
                placeholder="Название роли"
            />
            {unselectedRoles.map(role => (
                <RoleItem
                    key={role.key}
                    role={role}
                    onAdd={addRole}
                />
            ))}
        </div>
    );
}

export default Roles;
