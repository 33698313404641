export enum Team {
    Blue = "blue",
    Red = "red",
    Grey = "grey",
}

export interface Role {
    key: string
    name: string
    team: Team
    count?: number
}

export const roles: Role[] = [
    {
        key: 'blue_agent', 
        name: 'Агент', 
        team: Team.Blue
    },
    {
        key: 'blue_angel', 
        name: 'Ангел',
        team: Team.Blue
    },
    {
        key: 'blue_bouncer', 
        name: 'Вышибала', 
        team: Team.Blue
    },
    {
        key: 'blue_daemon', 
        name: 'Демон', 
        team: Team.Blue
    },
    {
        key: 'blue_dealer', 
        name: 'Дилер', 
        team: Team.Blue
    },
    {
        key: 'blue_doctor', 
        name: 'Доктор', 
        team: Team.Blue
    },
    {
        key: 'blue_clown', 
        name: 'Клоун', 
        team: Team.Blue
    },
    {
        key: 'blue_scammer', 
        name: 'Мошенник', 
        team: Team.Blue
    },
    {
        key: 'blue_invulnerable',
        name: 'Неуязвимый',
        team: Team.Blue
    },
    {
        key: 'blue_ambassador', 
        name: 'Посол', 
        team: Team.Blue
    },
    {
        key: 'blue_president', 
        name: 'Президент', 
        team: Team.Blue
    },
    {
        key: 'blue_team', 
        name: 'Синяя команда', 
        team: Team.Blue, 
        count: 7
    },
    {
        key: 'blue_humble', 
        name: 'Скромняга', 
        team: Team.Blue
    },
    {
        key: 'blue_blind', 
        name: 'Слепой', 
        team: Team.Blue
    },
    {
        key: 'blue_enforcer', 
        name: 'Страж порядка', 
        team: Team.Blue
    },
    {
        key: 'blue_criminal', 
        name: 'Уголовник', 
        team: Team.Blue
    },
    {
        key: 'blue_spy', 
        name: 'Шпион', 
        team: Team.Blue
    },
    {
        key: 'blue_eris',
        name: 'Ангел',
        team: Team.Blue
    },
    {
        key: 'red_agent', 
        name: 'Агент', 
        team: Team.Red
    },
    {
        key: 'red_angel', 
        name: 'Ангел', 
        team: Team.Red
    },
    {
        key: 'red_bomber', 
        name: 'Бомбист', 
        team: Team.Red
    },
    {
        key: 'red_bouncer', 
        name: 'Вышибала', 
        team: Team.Red
    },
    {
        key: 'red_daemon', 
        name: 'Демон', 
        team: Team.Red
    },
    {
        key: 'red_dealer', 
        name: 'Дилер', 
        team: Team.Red
    },
    {
        key: 'red_immunologist',
        name: 'Иммунолог',
        team: Team.Red
    },
    {
        key: 'red_engineer',
        name: 'Инженер',
        team: Team.Red
    },
    {
        key: 'red_clown', 
        name: 'Клоун', 
        team: Team.Red
    },
    {
        key: 'red_team', 
        name: 'Красная команда',
        team: Team.Red,
        count: 7
    },
    {
        key: 'red_cupid', 
        name: 'Купидон', 
        team: Team.Red
    },
    {
        key: 'red_scammer', 
        name: 'Мошенник', 
        team: Team.Red
    },
    {
        key: 'red_ambassador', 
        name: 'Посол', 
        team: Team.Red
    },
    {
        key: 'red_humble', 
        name: 'Скромняга', 
        team: Team.Red
    },
    {
        key: 'red_blind', 
        name: 'Слепой', 
        team: Team.Red
    },
    {
        key: 'red_enforcer', 
        name: 'Страж порядка',
        team: Team.Red
    },
    {
        key: 'red_criminal', 
        name: 'Уголовник', 
        team: Team.Red
    },
    {
        key: 'red_spy', 
        name: 'Шпион', 
        team: Team.Red
    },
    {
        key: 'red_dr_boom', 
        name: 'Dr. БУМ',
        team: Team.Red
    },
    {
        key: 'agoraphobe',
        name: 'Агорафоб',
        team: Team.Grey
    },
    {
        key: 'anarchist',
        name: 'Анархист',
        team: Team.Grey
    },
    {
        key: 'ahab',
        name: 'Ахаб',
        team: Team.Grey
    },
    {
        key: 'butler',
        name: 'Дворецкий',
        team: Team.Grey
    },
    {
        key: 'clone',
        name: 'Клон',
        team: Team.Grey
    },
    {
        key: 'bait',
        name: 'Приманка',
        team: Team.Grey
    },
    {
        key: 'sapper_robot',
        name: 'Робот-сапёр',
        team: Team.Grey
    },
]

export const rolesByKey = new Map(roles.map(role => [role.key, role]))

export function getRole(key: string, count?: number): Role {
    const role = rolesByKey.get(key) as Role;
    return {...role, count};
}